import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import ChannelLibrary from "views/ChannelLibrary/ChannelLibraryList";
import CustomTooltip from "components/Custom/CustomTooltip";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import SweetAlert from "react-bootstrap-sweetalert";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
  Collapse,
} from "react-bootstrap";
import {
  getClientEnvironment,
  getAvailablePorts,
  getEnvironmentConfiguration,
  updateEnvironment,
  getChannelLibrary,
  getChannelConfigurations
} from "graphql/queries.js";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { API, graphqlOperation } from "aws-amplify";
import ChannelForm from "./ChannelForm";
import { isStatusInAccessibleState } from "utilities/environmentUtilities";
import { convertDateListToString } from "utilities/dateUtilities";
import { DateObject } from "react-multi-date-picker";

function UpdateChannels() {
  // Contexts
  const notify = useContext(NotificationAlertContext);

  const { clientId, clientEnvironmentId } = useParams();
  const history = useHistory();

  // Create states to keep the information of selected values
  const [clientEnvironmentData, setClientEnvironmentData] = useState({});
  const [alert, setAlert] = useState(null);
  const [configuration, setConfiguration] = useState({});
  const [collapsibleAccordion, setCollapsibleAccordion] = useState(-1);
  const [newCollapsibleAccordion, setNewCollapsibleAccordion] = useState(-1);
  const [channels, setChannels] = useState([]);
  const [channelPortPathList, setChannelPortPathList] = useState([]);
  const [channelsBackUp, setChannelsBackUp] = useState([]);
  const [channelFormValues, setFormValues] = useState([]);
  const [channelThresholds, setChannelThresholds] = useState([]);
  const [channelsModified, setChannelsModified] = useState([]);
  const [availablePorts, setAvailablePorts] = useState([]);
  const [masterPorts, setMasterPorts] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [modifiedChannelsList, setModifiedChannelsList] = useState([]);
  const [updatedAt, setUpdatedAt] = useState("");
  const [configChannelLibraryList, setConfigChannelLibraryList] = useState([]);
  const [newChannelLibraryList, setNewChannelLibraryList] = useState([]);

  const [channelLibraryModal, setChannelLibraryModal] = useState(false);
  const [s3ChannelTypeModal, setS3ChannelTypeModal] = useState(false);
  const [s3ChannelConfiguration, setS3ChannelConfiguration] = useState("");

  const [selectedChannelFromLibrary, setSelectedChannelFromLibrary] =
    useState(null);

  const [showLoader, setShowLoader] = useState(true);
  // const [errorMessage, setErrorMessage] = useState(null);

  const fetchClientEnvironment = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getClientEnvironment, {
          client_id: clientId,
          client_environment_id: clientEnvironmentId,
          start_date: undefined,
          end_date: undefined,
          metric_date_list: convertDateListToString([new DateObject()]),
        })
      );
      let isAccessibleState = false;
      if (response.data?.getClientEnvironment?.success) {
        const data =
          response.data.getClientEnvironment.body?.client_environment_data;
        setClientEnvironmentData(data);
        isAccessibleState = isStatusInAccessibleState(data.deploy_status);
      } else {
        console.error(response.data?.getClientEnvironment?.message);
      }
      return response.data?.getClientEnvironment?.success && isAccessibleState;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const fetchChannelConfigurations = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getChannelConfigurations, {
          client_environment_id: clientEnvironmentId,
        })
      );
      if (response.data?.getChannelConfigurations?.success) {
        const channelConfigurations = response.data?.getChannelConfigurations?.body?.channel_configurations;
        setChannels(JSON.parse(JSON.stringify(channelConfigurations)));
        setChannelsBackUp(JSON.parse(JSON.stringify(channelConfigurations)));
        setChannelsModified(new Array(channelConfigurations.length).fill(false));
      } else {
        console.error(response.data?.getChannelConfigurations?.message);
      }
      return response.data?.getChannelConfigurations?.success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const fetchEnvironmentConfiguration = async () => {
    try {
      let channelLibraryIds = [];
      const response = await API.graphql(
        graphqlOperation(getEnvironmentConfiguration, {
          clientEnvironmentId: clientEnvironmentId,
        })
      );
      if (response.data?.getEnvironmentConfiguration?.success) {
        let config = JSON.parse(
          response.data?.getEnvironmentConfiguration?.body?.response
        );
        const updated_at = config.updated_at;
        config = config.client_environment_configuration;
        setConfiguration(config);
        setUpdatedAt(updated_at);
        const channelList = config.channel_port_path_list.map(
          (channelObj) => ({
            ...channelObj,
            support_tier: channelObj.support_tier || 3,
          })
        );
        setChannelPortPathList(channelList);

        for (const channelObj of channelList) {
          channelLibraryIds.push(channelObj.channel_library_id);
        }
        if (channelLibraryIds.length > 0) {
          fetchChannelLibraryIdList(channelLibraryIds);
        }
      } else {
        console.error(response.data?.getEnvironmentConfiguration?.message);
      }
      return response.data?.getEnvironmentConfiguration?.success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const updateEnvironmentConfiguration = async (environmentJson, triggerCodebuild) => {
    try {
      setShowLoader(true);
      const query = {
        environment_json: JSON.stringify(environmentJson),
        previously_updated_at: updatedAt,
        new_channel_library_id_list: newChannelLibraryList,
        update_db_channel_list: JSON.stringify(channelThresholds),
        trigger_codebuild: triggerCodebuild
      };
      const response = await API.graphql(
        graphqlOperation(updateEnvironment, query)
      );
      if (response.data?.updateEnvironment?.success) {
        notify("success");
        setConfiguration(environmentJson);
        setIsUpdating(true);
        setTimeout(() => {
          history.push({
            pathname: `/admin/clients/${clientId}`,
          });
        }, 3000);
      } else {
        console.error(response.data?.updateEnvironment?.message);
        notify("danger");
      }
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setShowLoader(false);
    }
  };

  // New channel related functions
  const setPorts = (data) => {
    let tempAvailablePorts = [];
    data.map((x) => {
      const temp = {
        value: x,
        label: x,
        name: "port",
      };
      tempAvailablePorts.push(temp);
    });
    if (data && !availablePorts.length) {
      setAvailablePorts(tempAvailablePorts);
      setMasterPorts(tempAvailablePorts);
    }
  };

  const fetchAvailablePorts = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getAvailablePorts, {
          clientEnvironmentId: clientEnvironmentId,
        })
      );
      if (response.data?.getAvailablePorts?.success) {
        setPorts(response?.data?.getAvailablePorts?.body?.available_ports);
      } else {
        console.error(response.data?.getAvailablePorts?.message);
      }
      return response.data?.getAvailablePorts?.success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const fetchChannelLibraryIdList = async (channelLibraryIdList) => {
    try {
      const response = await API.graphql(
        graphqlOperation(getChannelLibrary, {
          channel_library_id_list: channelLibraryIdList,
        })
      );
      if (response.data?.getChannelLibrary?.success) {
        setConfigChannelLibraryList(
          response?.data?.getChannelLibrary?.body?.channel_library_data
        );
      } else {
        console.error(response.data?.getChannelLibrary?.message);
      }
      return response.data?.getChannelLibrary?.success;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    async function fetchData() {
      setShowLoader(true);
      const promiseAllResults = await Promise.all([
        fetchClientEnvironment(),
        fetchChannelConfigurations(),
        fetchEnvironmentConfiguration(),
        fetchAvailablePorts(),
      ]);
      const showPage = promiseAllResults.every((value) => value);
      if (!showPage) {
        notify(
          "danger",
          "This environment is being updated, redirecting back to previous page."
        );
        history.goBack();
      }
      setShowLoader(false);
    }
    fetchData();
  }, []);

  const addFormFields = () => {
    const len = channelFormValues.length;
    const channelType =
      selectedChannelFromLibrary.mcc_channel_type === "S3"
        ? s3ChannelConfiguration
        : selectedChannelFromLibrary.mcc_channel_type;
    const channelObject = {
      id: uuidv4(),
      path: "",
      channel_library_id: selectedChannelFromLibrary.channel_library_id,
      port: 0,
      type: channelType,
      error_red: 20,
      error_yellow: 10,
      inactivity_red: 86400,
      inactivity_yellow: 20000,
      poll_frequency: 2,
      queue_red: 200,
      queue_yellow: 100,
      support_tier: 3,
      tmp_recommended: true,
    };
    if (
      channelType === "HTTP" ||
      channelType === "HTTP_S3" ||
      channelType === "FHIR_LIST" ||
      channelType === "HDH_LIST" ||
      channelType === "WS_LIST" ||
      channelType === "PDQV3_LIST" ||
      channelType === "PIXV3_LIST" ||
      channelType === "XDSB_LIST"
    ) {
      channelObject["http_auth_type"] = "BASIC";
    }
    setFormValues([...channelFormValues, {...channelObject, description: ""}]);
    setChannelThresholds([...channelThresholds, {...channelObject}]);
    setNewCollapsibleAccordion(len + 1);
  };

  const handleReset = (i) => {
    const channelsTemp = [...channels];
    channelsTemp[i] = { ...channelsBackUp[i] };
    setChannels(channelsTemp);
    setChannelPortPathList((oldChannels) => {
      const tempChannels = [...oldChannels];
      tempChannels[i]["tmp_recommended"] = channelsBackUp[i].tmp_recommended;
      return tempChannels;
    });
    setChannelsModified((oldData) => {
      const tempData = [...oldData];
      tempData[i] = false;
      return tempData;
    });
    const channelIndex = channelThresholds.findIndex(channel => channel.id === channelsBackUp[i].id);
    if (channelIndex !== -1) {
      channelThresholds.splice(channelIndex, 1);
    }
    if (modifiedChannelsList.includes(channels[i]["path"])) {
      setModifiedChannelsList(
        modifiedChannelsList.filter((value) => value !== channels[i]["path"])
      );
    }
  };

  const handleRemove = (i) => {
    const channelId = channelFormValues[i].id;
    const newFormValues = [...channelFormValues];
    const removedChannelLibraryId = channelFormValues[i].channel_library_id;
    let removeNewLibraryChannel = true;

    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    setChannelThresholds((oldChannelThresholds) => {
      const tempChannelThresholds = [...oldChannelThresholds];
      const channelIndex = tempChannelThresholds.findIndex(channel => channel.id === channelId);
      if (channelIndex !== -1) {
        tempChannelThresholds.splice(channelIndex, 1);
      }
      return tempChannelThresholds;
    });

    for (const newFormValue of newFormValues) {
      if (newFormValue.channel_library_id == removedChannelLibraryId) {
        removeNewLibraryChannel = false;
        break;
      }
    }

    if (removeNewLibraryChannel) {
      let newNewChannelLibraryList = [...newChannelLibraryList];
      newNewChannelLibraryList = newNewChannelLibraryList.filter(function (
        obj
      ) {
        return obj !== removedChannelLibraryId;
      });
      setNewChannelLibraryList(newNewChannelLibraryList);
    }

    setNewCollapsibleAccordion(-1);
  };

  const handleExistingChannelChange = (position, key, value) => {
    setChannels((oldChannels) => {
      const tempChannels = [...oldChannels];
      tempChannels[position][key] = value;
      return tempChannels;
    });
    setChannelPortPathList((oldChannels) => {
      const tempChannels = [...oldChannels];
      tempChannels[position][key] = value;
      return tempChannels;
    });
    setChannelThresholds((oldChannelThresholds) => {
      const tempChannelThresholds = [...oldChannelThresholds];
      const channelIndex = tempChannelThresholds.findIndex(channel => channel.id === channelsBackUp[position].id);
      if (channelIndex !== -1) {
        tempChannelThresholds[channelIndex][key] = value;
      } else {
        tempChannelThresholds.push(channels[position]);
      }
      return tempChannelThresholds;
    });
    if (key === "tmp_recommended")
    {
      setChannelsModified((oldData) => {
        const tempData = [...oldData];
        tempData[position] = true;
        return tempData;
      });
    }
    if (!modifiedChannelsList.includes(channels[position]["path"])) {
      setModifiedChannelsList([
        ...modifiedChannelsList,
        channels[position]["path"],
      ]);
    }
  };

  const handleNewChannelChange = (position, key, value) => {
    setFormValues((channels) => {
      const tempChannels = [...channels];
      tempChannels[position][key] = value;
      if (key === "type") {
        if (
          value === "HTTP" ||
          value === "HTTP_S3" ||
          value === "FHIR_LIST" ||
          value === "HDH_LIST" ||
          value === "WS_LIST" ||
          value === "PDQV3_LIST" ||
          value === "PIXV3_LIST" ||
          value === "XDSB_LIST"
        ) {
          tempChannels[position]["http_auth_type"] = "BASIC";
        } else {
          delete tempChannels[position]["http_auth_type"];
        }
      }
      const channelPorts = tempChannels.map(({ port }) => ({
        value: port,
        label: port === 0 ? "" : port,
      }));
      let ports = [...masterPorts];
      ports = ports.filter((x) => {
        return (
          channelPorts.filter((y) => {
            return x.value == y.value;
          }).length <= 0
        );
      });
      setAvailablePorts(ports);
      return tempChannels;
    });
    setChannelThresholds((oldChannelThresholds) => {
      const tempChannelThresholds = [...oldChannelThresholds];
      tempChannelThresholds[position][key] = value;
      return tempChannelThresholds;
    });
  };

  const handleNewConfigChannelLibrary = () => {
    const found = configChannelLibraryList.some(
      (channelLibraryObj) =>
        channelLibraryObj.channel_library_id ===
        selectedChannelFromLibrary.channel_library_id
    );
    if (!found) {
      setConfigChannelLibraryList([
        ...configChannelLibraryList,
        selectedChannelFromLibrary,
      ]);
      setNewChannelLibraryList([
        ...newChannelLibraryList,
        selectedChannelFromLibrary.channel_library_id,
      ]);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let invalidChannelPath = false;
    let emptyChannelType = false;
    let emptyChannelPort = false;
    const addedChannelsList = [];

    for (const channel of channelFormValues) {
      if (!/^([a-z0-9-]{4,80}){1}$/.test(channel.path)) {
        invalidChannelPath = true;
      }
      if (channel.type === "") {
        emptyChannelType = true;
      }
      if (
        (channel.type === "HTTP" ||
          channel.type === "TCP" ||
          channel.type === "DICOM_LIST" ||
          channel.type === "FHIR_LIST" ||
          channel.type === "HDH_LIST" ||
          channel.type === "WS_LIST" ||
          channel.type === "PDQV2_LIST" ||
          channel.type === "PDQV3_LIST" ||
          channel.type === "PIXV2_LIST" ||
          channel.type === "PIXV3_LIST" ||
          channel.type === "XDSB_LIST") &&
        channel.port === 0
      ) {
        emptyChannelPort = true;
      }
      addedChannelsList.push(`"${channel.path}"`);
    }

    if (invalidChannelPath) notify("danger", "Please provide a valid Deploy Channel Name.");
    else if (emptyChannelType) notify("danger", "Please Select Channel Type.");
    else if (emptyChannelPort) notify("danger", "Please Select Channel Port.");
    else {
      const configurationCopy = { ...configuration };
      const channelsCopy = [...channelFormValues];
      configurationCopy.channel_port_path_list = [...channelPortPathList, ...channelsCopy];
      const triggerCodebuild = channelsModified.some(element => element) || (channelsCopy.length > 0);
      setAlert(
        <SweetAlert
          showCancel
          title={"Are you sure?"}
          onConfirm={() => {
            notify("info", "Creating Update Channels Job.");
            updateEnvironmentConfiguration(configurationCopy, triggerCodebuild);
            setAlert(null);
          }}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
        >
          {modifiedChannelsList.length > 0 && (
            <p>{`Channel(s) to be modified: ${modifiedChannelsList
              .map((name) => `"${name}"`)
              .join(", ")}.`}</p>
          )}
          {addedChannelsList.length > 0 && (
            <p>{`Channel(s) to be created: ${addedChannelsList.join(
              ", "
            )}.`}</p>
          )}
        </SweetAlert>
      );
    }
  };

  const handleChannelLibrarySelect = () => {
    handleNewConfigChannelLibrary();
    addFormFields();
  };

  if (showLoader) return <Spinner animation="grow" />;

  // if (errorMessage) return errorMessage;

  return (
    <>
      {alert}
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Update Channels</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <form onSubmit={handleSubmit}>
              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">
                    {clientEnvironmentData.client_environment_name} (
                    {clientEnvironmentId})
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md="12">
                      <div className="accordions" id="accordion">
                        {channels.map((channel, index) => {
                          return (
                            <Card key={channel.id}>
                              <Card.Header>
                                <Card.Title as="h4">
                                  <a
                                    aria-expanded={
                                      collapsibleAccordion === index + 1
                                    }
                                    data-toggle="collapse"
                                    href="#mcc"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      collapsibleAccordion === index + 1
                                        ? setCollapsibleAccordion(-1)
                                        : setCollapsibleAccordion(index + 1);
                                    }}
                                  >
                                    Existing Channel: {channel.path}{" "}
                                    <b className="caret"></b>
                                  </a>
                                </Card.Title>
                              </Card.Header>
                              <Collapse
                                className="card-collapse"
                                id={`collapseHover-${index + 1}`}
                                in={collapsibleAccordion === index + 1}
                              >
                                <Card.Body>
                                  <ChannelForm
                                    index={index}
                                    isEdit={true}
                                    channelData={channel}
                                    availablePorts={availablePorts}
                                    configChannelLibrary={
                                      configChannelLibraryList
                                    }
                                    onChange={(key, value) => {
                                      handleExistingChannelChange(
                                        index,
                                        key,
                                        value
                                      );
                                    }}
                                    onReset={() => handleReset(index)}
                                  />
                                </Card.Body>
                              </Collapse>
                            </Card>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="accordions" id="accordion">
                        {channelFormValues.map((channel, index) => {
                          return (
                            <Card key={channel.id}>
                              <Card.Header>
                                <Card.Title as="h4">
                                  <a
                                    aria-expanded={
                                      newCollapsibleAccordion === index + 1
                                    }
                                    data-toggle="collapse"
                                    href="#mcc"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      newCollapsibleAccordion === index + 1
                                        ? setNewCollapsibleAccordion(-1)
                                        : setNewCollapsibleAccordion(index + 1);
                                    }}
                                  >
                                    New Channel: {channel.path}{" "}
                                    <b className="caret"></b>
                                  </a>
                                </Card.Title>
                              </Card.Header>
                              <Collapse
                                className="card-collapse"
                                id={`collapseHover-${index + 1}`}
                                in={newCollapsibleAccordion === index + 1}
                              >
                                <Card.Body>
                                  <ChannelForm
                                    index={index}
                                    isEdit={false}
                                    channelData={channel}
                                    availablePorts={availablePorts}
                                    configChannelLibrary={
                                      configChannelLibraryList
                                    }
                                    onChange={(key, value) => {
                                      handleNewChannelChange(index, key, value);
                                    }}
                                    onRemove={() => handleRemove(index)}
                                  />
                                </Card.Body>
                              </Collapse>
                            </Card>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <Button
                          className="btn-fill"
                          type="button"
                          variant="info"
                          onClick={() => setChannelLibraryModal(true)}
                        >
                          Add Channel
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col md="12">
                      <Button
                        className="btn-fill"
                        variant="primary"
                        type="submit"
                        style={{ float: "right", backgroundColor: "#447DF7" }}
                        disabled={
                          !(
                            !isUpdating &&
                            (modifiedChannelsList.length > 0 ||
                              channelFormValues.length > 0)
                          )
                        }
                      >
                        Update Channels
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </form>
          </Col>
        </Row>
        <Modal
          toggle={() => setChannelLibraryModal(!channelLibraryModal)}
          size="xl"
          isOpen={channelLibraryModal}
          scrollable={false}
        >
          <ModalHeader></ModalHeader>
          <ModalBody>
            <ChannelLibrary
              setSelectedChannelState={setSelectedChannelFromLibrary}
            ></ChannelLibrary>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                setSelectedChannelFromLibrary(null);
                setChannelLibraryModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="button"
              disabled={!selectedChannelFromLibrary}
              onClick={() => {
                if (selectedChannelFromLibrary.mcc_channel_type === "S3") {
                  setS3ChannelConfiguration("CHAN_READ");
                  setS3ChannelTypeModal(true);
                } else {
                  handleChannelLibrarySelect();
                  setChannelLibraryModal(false);
                }
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          toggle={() => setS3ChannelTypeModal(!s3ChannelTypeModal)}
          size="md"
          isOpen={s3ChannelTypeModal}
          scrollable={false}
        >
          <ModalHeader style={{ justifyContent: "center" }}>
            <h3>S3 Channel Configuration</h3>
          </ModalHeader>
          <ModalBody>
            <Form.Check>
              <Form.Check.Label>
                <Form.Check.Input
                  defaultValue="httpToS3"
                  id="httpToS3"
                  name="s3ChannelConfigurationRadio"
                  type="radio"
                  onClick={() => setS3ChannelConfiguration("HTTP_S3")}
                ></Form.Check.Input>
                HTTP to S3
                <CustomTooltip
                  id={`httpToS3-tooltip`}
                  value="API Gateway Route drops message into S3 bucket and MC polls bucket for messages"
                >
                  <i
                    className="fa fa-info-circle"
                    style={{ marginLeft: "10px" }}
                  ></i>
                </CustomTooltip>
              </Form.Check.Label>
            </Form.Check>
            <Form.Check>
              <Form.Check.Label>
                <Form.Check.Input
                  defaultValue="sftpServer"
                  id="sftpServer"
                  name="s3ChannelConfigurationRadio"
                  type="radio"
                  onClick={() => setS3ChannelConfiguration("SFTP")}
                ></Form.Check.Input>
                SFTP Server
                <CustomTooltip
                  id={`sftpServer-tooltip`}
                  value="AWS Transfer family SFTP server sends messages to S3 bucket and MC polls bucket for messages"
                >
                  <i
                    className="fa fa-info-circle"
                    style={{ marginLeft: "10px" }}
                  ></i>
                </CustomTooltip>
              </Form.Check.Label>
            </Form.Check>
            <Form.Check>
              <Form.Check.Label>
                <Form.Check.Input
                  defaultChecked
                  defaultValue="none"
                  id="none"
                  name="s3ChannelConfigurationRadio"
                  type="radio"
                  onClick={() => setS3ChannelConfiguration("CHAN_READ")}
                ></Form.Check.Input>
                None
                <CustomTooltip
                  id={`none-tooltip`}
                  value="This channel will poll an external S3 bucket"
                >
                  <i
                    className="fa fa-info-circle"
                    style={{ marginLeft: "10px" }}
                  ></i>
                </CustomTooltip>
              </Form.Check.Label>
            </Form.Check>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                setS3ChannelTypeModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                handleChannelLibrarySelect();
                setS3ChannelTypeModal(false);
                setChannelLibraryModal(false);
              }}
            >
              Accept
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
}

export default UpdateChannels;
