export const copyToClipboard = async (text) => {
    try {
        await navigator.clipboard.writeText(text);
        console.log("Text copied to clipboard");
    } catch (err) {
        console.error("Failed to copy text: ", err);
    }
};

export const checkEvenNumberQuotes = (str) => {
    return (String(str).match(/"/g) || []).length % 2 === 0;
};

export const cleanStringToArray = (line) => {
    // Use regex to match comma-separated values and keep quoted text intact
    const regex = /(".*?"|[^,\s]+)(?=\s*,|\s*$)/g;
    const matches = [...line.matchAll(regex)];

    // Clean up each match by removing surrounding quotes and trimming
    return matches.map((match) => match[0].trim().replace(/^["]|["]$/g, ""));
};