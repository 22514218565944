import CustomTooltip from "components/Custom/CustomTooltip";

function CustomStatus({ value }) {
  let color = "red";
  let icon = "fa fa-thumbs-down";
  let title = "Major issue found"

  if (value != null && value < 1.5) {
    if (value < 0.5) {
      icon = "fa fa-thumbs-up";
      color = "green";
      title = "No issue found"
    } else {
      icon = "fa fa-thumbs-up fa-rotate-270";
      color = "gold";
      title = "Minor issue found"
    }
  }
  return <CustomTooltip
            value={title}
          >
            <i
              className={icon}
              style={{color, marginLeft: "10px" }}
            ></i>
          </CustomTooltip>;
}

export default CustomStatus;