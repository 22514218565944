import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import _ from "lodash";
import { Link } from "react-router-dom";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Form,
} from "react-bootstrap";
import Select from "react-select";
import ReactTable from "components/ReactTable/ReactTableWithDynamicPagination.js";
import { withAuthenticator } from "@aws-amplify/ui-react";

import { getBatchDetails } from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

function BatchDetails(props) {
  // Contexts
  const notify = useContext(NotificationAlertContext);

  const { clientEnvironmentId, clientId, batchId } = useParams();

  const history = useHistory();
  // States
  const [showLoader, setShowLoader] = useState(true);
  const [messageStatusValue, setMessageStatus] = React.useState(
    props.location.state?.messageStatusValue
  );
  const [messageBatchData, setMessageBatchData] = useState({
    messageBatchDetailsData: [],
    clientId: "",
    clientName: "",
    clientEnvironmentId: "",
    clientEnvironmentName: "",
    channelId: "",
    channelName: "",
    batchId: "",
    emailId: "",
    batchTimestamp: "",
  });

  const headers = [
    { key: "new_message_id", label: "New Message Id" },
    { key: "original_message_id", label: "Original Message Id" },
    { key: "reprocess_timestamp", label: "Reprocess Time" },
    { key: "reprocess_status_text", label: "Reprocess Status Text" },
    { key: "error_total", label: "Error Total" },
    { key: "filter_total", label: "Filter Total" },
    { key: "sent_total", label: "Sent Total" },
    { key: "reprocess_details", label: "Reprocess Details" },
  ];

  // Pagination Starts
  const columnMapping = {
    "New Message Id": "new_message_id",
    "Original Message Id": "original_message_id",
    "Reprocess Time": "reprocess_timestamp",
    "Batch Status": "reprocess_status_text",
    "Reprocess Details": "reprocess_details",
  };

  const linkCustom = {
    position: "relative",
    top: "2px",
  };
  const [totalCount, setTotalCount] = useState(0);
  const numberOfRowsData = [25, 50, 100];

  const [currentLimit, setCurrentLimit] = useState(numberOfRowsData[1]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [ascOrDescValue, setAscOrDescValue] = useState("ASC");
  const [sortValue, setSortValue] = useState("New Message Id");

  const setStateForPagnination = async (key, value) => {
    if (key === "currentLimit") {
      setCurrentLimit(value);
    } else if (key === "offset") {
      setCurrentOffset(value);
    } else if (key === "ascOrDesc") {
      setAscOrDescValue(value);
    } else {
      setSortValue(value);
    }
  };
  // Pagination Ends

  const fetchMessageBatchDetails = async (
    variables = {},
    isCSVDownload = false
  ) => {
    try {
      setShowLoader(true);
      const payload = {
        sortFilter: variables.sortFilter
          ? variables.sortFilter
          : columnMapping[sortValue],
        client_environment_id: clientEnvironmentId,
        client_id: clientId,
        batch_id: batchId,
      };
      variables = { ...variables, ...payload };
      const response = await API.graphql(
        graphqlOperation(getBatchDetails, variables)
      );
      if (response.data?.getBatchDetails?.success) {
        if (!isCSVDownload) {
          const messageBatchData = _.get(
            response,
            "data.getBatchDetails.body.message_batch_data"
          );
          const totalChannelCount = _.get(
            response,
            "data.getBatchDetails.body.total_message_batch_count"
          );
          const messageBatchDataExtracted = {
            messageBatchDetailsData:
              messageBatchData.message_batch_details_data,
            clientId: messageBatchData.client_id,
            clientName: messageBatchData.client_name,
            clientEnvironmentId: messageBatchData.client_environment_id,
            clientEnvironmentName: messageBatchData.client_environment_name,
            channelName: messageBatchData.channel_name,
            channelId: messageBatchData.channel_id,
            batchId: messageBatchData.batch_id,
            emailId: messageBatchData.email_id,
            batchTimestamp: messageBatchData.batch_timestamp,
          };
          setTotalCount(totalChannelCount);
          setMessageBatchData(messageBatchDataExtracted);
          notify("success");
        } else {
          const filter = {
            "Client Environment Name": messageBatchData.clientEnvironmentName,
            "Client Name": messageBatchData.clientName,
            "Batch Id": payload.batch_id,
            "Message Status": messageStatusValue?.map((item) => item.label),
          };
          const messageDataForCSV = _.get(
            response,
            "data.getBatchDetails.body.message_batch_data.message_batch_details_data"
          );
          return { messageDataForCSV, filter };
        }
      } else {
        console.error(response.data?.getBatchDetails?.message);
      }
      return response.data?.getBatchDetails?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  const reset = () => {
    setMessageStatus([]);
  };

  useEffect(() => {
    async function fetchData() {
      const filterObj = {
        offSet: 0,
        limit: currentLimit,
        sortFilter: columnMapping[sortValue],
        sortType: ascOrDescValue,
      };
      setCurrentOffset(0);
      const isSuccess = await fetchMessageBatchDetails(filterObj);
      if (!isSuccess) {
        notify("danger");
        history.goBack();
      }
    }
    fetchData();
  }, []);

  if (showLoader) return <Spinner animation="grow" />;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Message Batch Details</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h5>
              Client Name:{" "}
              <span id="clientId">{messageBatchData.clientName}</span>
            </h5>
            <h5>
              Client Environment Name:{" "}
              <span id="clientEnvironmentId">
                {messageBatchData.clientEnvironmentName}
              </span>
            </h5>
            <h5>Channel Name: {messageBatchData.channelName}</h5>
            <h5>Batch Id: {messageBatchData.batchId}</h5>
            <h5>User: {messageBatchData.emailId}</h5>
            <h5>
              Batch Time:
              {new Date(messageBatchData.batchTimestamp).toLocaleString()}
            </h5>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col md="3">
            <Form.Group>
              <label>Message Status</label>
              <Select
                className="react-select primary"
                classNamePrefix="react-select"
                name="messageStatusValue"
                id="messageStatusValue"
                isMulti
                value={messageStatusValue}
                onChange={(value) => setMessageStatus(value)}
                options={[
                  { value: "SENT", label: "Sent" },
                  { value: "FILTERED", label: "Filtered" },
                  { value: "ERROR", label: "Error" },
                  { value: "UNKNOWN", label: "Unknown" },
                ]}
                placeholder="Select Message Status"
              />
            </Form.Group>
          </Col>
          <Col md="9" style={{ position: "relative", top: "30px" }}>
            <Button
              className="btn-fill"
              type="button"
              variant="primary"
              style={{ marginRight: "10px" }}
              onClick={(e) => {
                const filterObj = {
                  offSet: 0,
                  limit: currentLimit,
                  sortFilter: columnMapping[sortValue],
                  sortType: ascOrDescValue,
                  messageStatus:
                    messageStatusValue?.length === 4
                      ? undefined
                      : messageStatusValue?.map((x) => {
                          return x.value;
                        }),
                };
                setCurrentOffset(0);
                fetchMessageBatchDetails(filterObj);
              }}
            >
              Search batch Details
            </Button>
            <Button
              className="btn-fill"
              variant="primary"
              onClick={() => reset()}
            >
              Reset
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <Card className="strpied-tabled-with-hover">
                  <Card.Body className="table-responsive p-0">
                    <ReactTable
                      data={messageBatchData.messageBatchDetailsData}
                      totalCount={totalCount}
                      functionCallBack={fetchMessageBatchDetails}
                      setStateForPagnination={setStateForPagnination}
                      currentLimit={currentLimit}
                      currentOffset={currentOffset}
                      ascOrDescValue={ascOrDescValue}
                      sortValue={sortValue}
                      numberOfRowsData={numberOfRowsData}
                      columnMapping={columnMapping}
                      tableName="Batch Details"
                      CSVHeaders={headers}
                      CSVFileName="Batch_Details_Export"
                      notify={notify}
                      columns={[
                        {
                          Header: "New Message Id",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            if (row.message_data_available) {
                              return (
                                <Link
                                  className="btn-social btn-link btn btn-twitter"
                                  type="submit"
                                  variant="primary"
                                  style={linkCustom}
                                  to={{
                                    pathname: `/admin/message-batches/batch-details/${clientId}/${clientEnvironmentId}/${batchId}/view-message/${row.new_inbound_message_idempotency_key}?clientEnvironmentId=${clientEnvironmentId}&channelId=${messageBatchData.channelId}&messageId=${row.new_message_id}&messageDataAvailable=${row.message_data_available}`,
                                    state: {
                                      ...messageBatchData,
                                      messageStatusValue,
                                      isBatchDetails: true,
                                      message_id: row.new_message_id,
                                      message_data_available:
                                        row.message_data_available,
                                    },
                                  }}
                                >
                                  {row.new_message_id}
                                </Link>
                              );
                            } else {
                              return row.new_message_id
                                ? row.new_message_id
                                : "No Message Id Found";
                            }
                          },
                          accessor: "new_message_id",
                          filterable: false,
                        },
                        {
                          Header: "Original Message Id",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            if (row.message_data_available) {
                              return (
                                <Link
                                  className="btn-social btn-link btn btn-twitter"
                                  type="submit"
                                  variant="primary"
                                  style={linkCustom}
                                  to={{
                                    pathname: `/admin/message-batches/batch-details/${clientId}/${clientEnvironmentId}/${batchId}/view-message/${row.original_inbound_message_idempotency_key}?clientEnvironmentId=${clientEnvironmentId}&channelId=${messageBatchData.channelId}&messageId=${row.new_message_id}&messageDataAvailable=${row.message_data_available}`,
                                    state: {
                                      ...messageBatchData,
                                      messageStatusValue,
                                      isBatchDetails: true,
                                      message_id: row.original_message_id,
                                      message_data_available:
                                        row.message_data_available,
                                    },
                                  }}
                                >
                                  {row.original_message_id}
                                </Link>
                              );
                            } else {
                              return row.original_message_id
                                ? row.original_message_id
                                : "No Message Id Found";
                            }
                          },
                          accessor: "original_message_id",
                          filterable: false,
                        },
                        {
                          Header: "Reprocess Time",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            return new Date(
                              row.reprocess_timestamp
                            ).toLocaleString();
                          },
                          filterable: false,
                          showTooltip: true,
                        },
                        {
                          Header: "Batch Status",
                          accessor: "reprocess_status_text",
                          filterable: false,
                        },
                        {
                          Header: "Channel Outcome",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            let connectorMsgText = "";
                            if (row.sent_total > 0) {
                              connectorMsgText += "SENT";
                            }
                            if (row.filter_total > 0) {
                              if (connectorMsgText != "") {
                                connectorMsgText += " / ";
                              }
                              connectorMsgText += "FILTERED";
                            }
                            if (row.error_total > 0) {
                              if (connectorMsgText != "") {
                                connectorMsgText += " / ";
                              }
                              connectorMsgText += "ERROR";
                            }
                            if (
                              row.sent_total == 0 &&
                              row.filter_total == 0 &&
                              row.error_total == 0
                            ) {
                              if (connectorMsgText != "") {
                                connectorMsgText += " / ";
                              }
                              connectorMsgText += "UNKNOWN";
                            }
                            return (
                              <span id={"connectorMsgText-" + cell.row.id}>
                                {connectorMsgText}
                              </span>
                            );
                          },
                          filterable: false,
                          sortable: false,
                        },
                        {
                          Header: "Reprocess Details",
                          accessor: "reprocess_details",
                          filterable: false,
                          showTooltip: true,
                        },
                      ]}
                      hidePagination={false}
                    />
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default withAuthenticator(BatchDetails, { hideSignUp: true });
