import React, { useEffect } from "react";
import { NotificationAlertContext } from "../../contexts/notificationAlertContext.js";
import { useHistory, useParams } from "react-router-dom";

import { API, graphqlOperation } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import SweetAlert from "react-bootstrap-sweetalert";
import _ from "lodash";

import {
  processChannelMessage,
  getS3DataForInboundMessages,
} from "../../graphql/queries.js";
// react-bootstrap components
import {
  Container,
  Card,
  Row,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
  Button,
  Spinner,
} from "react-bootstrap";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Chat from "views/Components/Chat.js";

const ViewMessage = (props) => {
  // Contexts
  const notify = React.useContext(NotificationAlertContext);
  const history = useHistory();

  const searchParams = new URLSearchParams(window.location.search);

  const clientEnvironmentId = searchParams.get("clientEnvironmentId");
  const channelId = searchParams.get("channelId");
  const messageId = searchParams.get("messageId");
  const messageDataAvailable = searchParams.get("messageDataAvailable");

  // SSO related information
  const groupname =
    props.user.signInUserSession.accessToken.payload["cognito:groups"][0];
  const isInternalUser = groupname === "internal";

  let { batchId, inboundMessageIdempotencyKey } = useParams();

  inboundMessageIdempotencyKey = inboundMessageIdempotencyKey.split("?")[0];
  const clientId = props.location.state?.clientId;

  const [clientName, setClientName] = React.useState("");
  const [clientEnvironmentName, setClientEnvironmentName] = React.useState("");
  const [channelName, setChannelName] = React.useState("");
  const [messageDetails, setMessageDetails] = React.useState({
    msgContentB64: "",
    msgDate: "",
    messageId: "",
    sizeInBytes: "",
    connectorData: "",
    inboundMessageIdempotencyKey: "",
  });

  const [isDescriptionEditable, setIsDescriptionEditable] =
    React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [viewChatModal, setViewChatModal] = React.useState(false);
  const [initialPrompt, setInitialPrompt] = React.useState("");

  let redirectUrl = "";
  if (batchId) {
    redirectUrl = "/admin/message-batches";
  } else {
    redirectUrl = "/admin/find-or-reprocess";
  }

  const zindex = {
    zIndex: "9999",
  };
  const sweetAlertCustom = {
    display: "block",
    marginTop: "-100px",
    fontSize: "14px",
    overflow: "auto",
    textAlign: "left",
    width: "80%",
  };
  const descriptionButtonsStyle = {
    margin: "5px",
    float: "right",
  };
  const ellipsisStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "300px",
  };
  const noMargin = {
    margin: 0,
  };
  const CONNECTOR_TIMER = 10000;

  const processMessage = async (
    clientEnvironmentId,
    channelId,
    channelName,
    message_id,
    idempotency_key,
    message_data
  ) => {
    try {
      notify("info");
      let payload;
      if (message_data === messageDetails.msgContentB64) {
        payload = {
          client_environment_id: clientEnvironmentId,
          channel_id: channelId,
          idempotency_key: idempotency_key,
        };
      } else {
        payload = {
          client_environment_id: clientEnvironmentId,
          channel_id: channelId,
          message_data: message_data,
        };
      }
      const response = await API.graphql(
        graphqlOperation(processChannelMessage, payload)
      );
      if (response.data?.processChannelMessage?.success) {
        notify(
          "success",
          `Successfully reprocessed Original Message ID ${message_id} for ${channelName}. It's New Message ID is ${response.data.processChannelMessage.body?.response}.`,
          -1
        );
      } else {
        console.error(response.data?.processChannelMessage?.message);
        notify(
          "danger",
          `Error reprocessing Message ID ${message_id} for ${channelName}.`,
          -1
        );
      }
      return response.data?.processChannelMessage?.success;
    } catch (error) {
      console.error(error);
      notify(
        "danger",
        `Error reprocessing Message ID ${message_id} for ${channelName}.`,
        -1
      );
      return false;
    }
  };

  const fetchS3DataForInboundMessages = async (messageId, query) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(getS3DataForInboundMessages, query)
      );
      if (response.data?.getS3DataForInboundMessages?.success) {
        const s3ResponseString = _.get(
          response,
          "data.getS3DataForInboundMessages.body.s3_data"
        );
        const keyValuePairs = s3ResponseString.slice(1, -1).split(", ");

        const data = {};
        keyValuePairs.forEach((pair) => {
          const [key, value] = pair.split(/=(.+)/);
          data[key] = isNaN(value) ? value : parseFloat(value) || value;
        });
        const connectorData = _.get(
          response,
          "data.getS3DataForInboundMessages.body.connector_data"
        );
        const searchTokenData = _.get(
          response,
          "data.getS3DataForInboundMessages.body.search_token_data"
        );
        const channelData = _.get(
          response,
          "data.getS3DataForInboundMessages.body.channel_data"
        );
        if (isInternalUser) {
          connectorData.map((x) => {
            if (x.connector_msg_status === "ERROR") {
              setErrorMessage(x.connector_msg_status);
              return;
            }
          });
        }

        setClientName(channelData?.client_name);
        setClientEnvironmentName(channelData?.client_environment_name);
        setChannelName(channelData?.channel_name);
        setMessageDetails({
          ...data,
          connectorData,
          searchTokenData,
          messageId,
          inboundMessageIdempotencyKey: query.inboundMessageIdempotencyKey,
        });
        setDescription(atob(data.msgContentB64));
        notify("success");
      } else {
        console.error(response.data?.getS3DataForInboundMessages?.message);
        notify("danger");
        history.goBack();
      }
    } catch (error) {
      console.error(error);
      notify("danger");
      history.goBack();
    } finally {
      setShowLoader(false);
    }
  };

  const callS3url = async (parameters) => {
    try {
      const jsonPacket = {
        inboundMessageIdempotencyKey: inboundMessageIdempotencyKey,
        clientEnvironmentId,
        channelId,
        messageId: parameters.messageId,
      };
      if (parameters.messageDataAvailable) {
        await fetchS3DataForInboundMessages(parameters.messageId, jsonPacket);
        return true;
      } else {
        setAlert(
          <SweetAlert
            warning
            showCancel
            style={sweetAlertCustom}
            title="Not Existing"
            confirmBtnBsStyle="info"
            confirmBtnText="Ok"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            focusCancelBtn
          >
            <h2>Message too large to display</h2>
          </SweetAlert>
        );
        history.goBack();
        return false;
      }
    } catch (error) {
      history.push(redirectUrl);
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      setShowLoader(true);
      const parameters = {
        messageId: messageId,
        messageDataAvailable: messageDataAvailable,
      };
      const isSuccess = await callS3url(parameters);
      if (!isSuccess) {
        notify("danger");
        history.goBack();
      }
    }
    fetchData();
  }, []);

  const [alert, setAlert] = React.useState(null);

  const hideAlert = () => {
    setAlert(null);
  };

  if (showLoader) return <Spinner animation="grow" />;
  return (
    <>
      {alert}
      <Container fluid>
        <Card className="strpied-tabled-with-hover">
          <Card.Body>
            <Row>
              <Col md="12">
                <h3>Client Details</h3>
                <p style={noMargin}>Client Name: {clientName}</p>
                <p style={noMargin}>
                  Environment Name: {clientEnvironmentName}
                </p>
                <p style={noMargin}>Channel Name: {channelName}</p>
                <p style={noMargin}>
                  Message Date:{" "}
                  {new Date(messageDetails.msgDate).toLocaleString()}
                </p>
                <p style={noMargin}>Message Id: {messageDetails.messageId}</p>
                <p style={noMargin}>
                  Size In Bytes: {messageDetails.sizeInBytes}
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="strpied-tabled-with-hover">
          <Card.Body>
            <Row>
              <Col md="12">
                <h3>Search Token Details</h3>
                {messageDetails.connectorData &&
                  messageDetails.connectorData.length > 0 && (
                    <>
                      <Table className="table-hover table-striped w-full">
                        <thead>
                          <tr>
                            <th>Search Token Key</th>
                            <th>Search Token Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {messageDetails.searchTokenData.map(
                            (tokenData, key) => {
                              return (
                                <tr key={key}>
                                  <td>{tokenData.search_token_key}</td>
                                  <td>{tokenData.search_token_value}</td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </>
                  )}
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card className="strpied-tabled-with-hover">
          <Card.Body>
            <Row>
              <Col md="12">
                <h3>Connector Details</h3>
                {messageDetails.connectorData &&
                  messageDetails.connectorData.length > 0 && (
                    <>
                      <Table className="table-hover table-striped w-full">
                        <thead>
                          <tr>
                            <th>Connector ID</th>
                            <th>Connector Name</th>
                            <th>Connector Msg Status</th>
                            <th>Connector Error Text</th>
                            {isInternalUser && errorMessage && <th></th>}
                            <th>Connector Date Processed</th>
                            <th>Process Time (sec)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {messageDetails.connectorData.map(
                            (connector, key) => {
                              return (
                                <tr key={key}>
                                  <td>{connector.connector_id}</td>
                                  <td>{connector.connector_name}</td>
                                  <td>{connector.connector_msg_status}</td>
                                  <td>
                                    {
                                      <OverlayTrigger
                                        key={`event-type-${connector.connector_id}`}
                                        placement="right"
                                        overlay={
                                          <Tooltip
                                            id={`event-type-tooltip-${connector.connector_id}`}
                                            style={zindex}
                                          >
                                            {connector.connector_error_text}
                                          </Tooltip>
                                        }
                                      >
                                        <div style={ellipsisStyle}>
                                          {connector.connector_error_text
                                            ? connector.connector_error_text
                                            : ""}
                                        </div>
                                      </OverlayTrigger>
                                    }
                                  </td>
                                  {isInternalUser && errorMessage && (
                                    <td>
                                      {connector.connector_msg_status ===
                                        "ERROR" && (
                                        <Button
                                          variant="info"
                                          type="button"
                                          onClick={() => {
                                            setInitialPrompt(
                                              `Can you help me fix this Mirth Connect channel error?\n${connector.connector_error_text}`
                                            );
                                            setViewChatModal(true);
                                          }}
                                        >
                                          Mirth Assist
                                        </Button>
                                      )}
                                    </td>
                                  )}
                                  <td>
                                    {new Date(
                                      connector.connector_date_processed
                                    ).toLocaleString()}
                                  </td>
                                  <td>
                                    {Math.round(
                                      CONNECTOR_TIMER *
                                        connector.process_time_sec
                                    ) / CONNECTOR_TIMER}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    </>
                  )}
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <textarea
                  id="messageDescription"
                  style={{
                    minWidth: "100%",
                    minHeight: "200px",
                  }}
                  disabled={!isDescriptionEditable}
                  className="form-control"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                ></textarea>
                <Button
                  className="btn-fill"
                  style={descriptionButtonsStyle}
                  type="button"
                  variant="primary"
                  disabled={isDescriptionEditable}
                  onClick={() => setIsDescriptionEditable(true)}
                >
                  Edit
                </Button>
                <Button
                  className="btn-fill"
                  style={descriptionButtonsStyle}
                  type="button"
                  variant="primary"
                  disabled={!isDescriptionEditable}
                  onClick={() => {
                    setDescription(atob(messageDetails.msgContentB64));
                    setIsDescriptionEditable(false);
                  }}
                >
                  Discard
                </Button>
              </Col>
            </Row>
            <Row style={{ justifyContent: "center" }}>
              <Col md="12">
                <Button
                  variant="info"
                  type="button"
                  onClick={() => {
                    processMessage(
                      clientEnvironmentId,
                      channelId,
                      channelName,
                      messageDetails.messageId,
                      messageDetails.inboundMessageIdempotencyKey,
                      btoa(description)
                    );
                  }}
                >
                  Reprocess Message
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Modal
          toggle={() => setViewChatModal(!viewChatModal)}
          size="xl"
          isOpen={viewChatModal}
          scrollable={true}
        >
          <ModalHeader style={{ justifyContent: "center" }}>
            Mirth Assist
          </ModalHeader>
          <ModalBody>
            <Chat
              clientId={clientId}
              clientEnvironmentId={clientEnvironmentId}
              channelId={channelId}
              inboundMessageIdempotencyKey={
                messageDetails.inboundMessageIdempotencyKey
              }
              initialPrompt={initialPrompt}
            />
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center" }}>
            <Button
              variant="secondary"
              type="button"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setViewChatModal(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
};

export default withAuthenticator(ViewMessage, { hideSignUp: true });
