import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import * as moment from "moment";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import _ from "lodash";

// react-bootstrap components
import {
  Button,
  Card,
  Collapse,
  Table,
  Form,
  Container,
  Row,
  Col,
  Spinner,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import CustomReactDatetime from "components/Custom/CustomReactDatetime.js";
import CustomMultiDateSelect from "components/Custom/CustomMultiDateSelect";
import CustomTooltip from "components/Custom/CustomTooltip";
import CustomStatus from "components/Custom/CustomStatus";
import CloudwatchMetric from "views/Components/CloudwatchMetric.js";
import { DateObject } from "react-multi-date-picker";
import ReactTable from "components/ReactTable/ReactTableWithDynamicPagination.js";
import { convertDateListToString } from "utilities/dateUtilities";
import { copyToClipboard } from "utilities/commonUtilities";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import { withAuthenticator } from "@aws-amplify/ui-react";

import {
  getClientEnvironment,
  getEnvironmentResourceDetails,
  addMCUser,
  postChannelActionRequest,
  refreshChannelTokens,
  updateClientEnvironmentName,
} from "graphql/queries.js";
import SweetAlert from "react-bootstrap-sweetalert";

import { API, graphqlOperation } from "aws-amplify";
import { isStatusInAccessibleState } from "utilities/environmentUtilities";

function Environment(props) {
  // Contexts
  const notify = useContext(NotificationAlertContext);

  const { clientId, clientEnvironmentId } = useParams();
  const groupname =
    props.user.signInUserSession.accessToken.payload["cognito:groups"][0];
  const history = useHistory();
  // States
  const [clientEnvironmentData, setClientEnvironmentData] = useState({
    channels: [],
  });
  const [showLoader, setShowLoader] = useState(true);
  const [mcUserData, setMCUserData] = useState({});
  // const [errorMessage, setErrorMessage] = useState(null);

  const [jobRanAt, setJobRanAt] = useState(null);
  const [startDate, setStartDate] = useState(moment().subtract(1, "day"));
  const [endDate, setEndDate] = useState(moment());

  const [tempStartDate, setTempStartDate] = useState(
    moment().subtract(1, "day")
  );
  const [tempEndDate, setTempEndDate] = useState(moment());
  const [isCustomDate, setCustomDate] = useState(false);
  const [metricDateList, setMetricDateList] = useState([new DateObject()]);
  const [resourceDetailsModalOpen, setResourceDetailsModalOpen] =
    useState(false);
  const [mcUserModalOpen, setMCUserModalOpen] = useState(false);
  const [channelActionModalOpen, setChannelActionModalOpen] = useState(false);

  const [deployStatus, setDeployStatus] = useState(null);
  const [resourceDetails, setResourceDetails] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [metrics, setMetrics] = useState([]);
  const metricsList = [
    { label: "ECS - CPU Utilization", value: "ECS_CPU_UTILIZATION" },
    { label: "ECS - Memory Utilization", value: "ECS_MEM_UTILIZATION" },
    { label: "ECS - Network TX", value: "ECS_NETWORK_TX" },
    { label: "RDS - CPU Utilization", value: "RDS_CPU_UTILIZATION" },
    { label: "RDS - Freeable Memory", value: "RDS_FREE_MEMORY" },
    { label: "RDS - Free Storage Space", value: "RDS_FREE_STORAGE" },
    { label: "RDS - Read IOPS", value: "RDS_READ_IOPS" },
    { label: "RDS - Write IOPS", value: "RDS_WRITE_IOPS" },
    { label: "RDS - Swap Usage", value: "RDS_SWAP_USAGE" },
    { label: "RDS - Database Connections", value: "RDS_DB_CONNECTIONS" },
    { label: "S3 - Bytes Uploaded", value: "S3_ARCHIVE_BYTES_UPLOADED" },
    { label: "S3 - Bytes Downloaded", value: "S3_ARCHIVE_BYTES_DOWNLOADED" },
    { label: "S3 - Get Requests", value: "S3_ARCHIVE_GET_REQUESTS" },
    { label: "S3 - Put Requests", value: "S3_ARCHIVE_PUT_REQUESTS" },
    { label: "S3 - 4xx Errors", value: "S3_ARCHIVE_4XX_ERRORS" },
    { label: "S3 - 5xx Errors", value: "S3_ARCHIVE_5XX_ERRORS" },
  ];
  const [selectedMetric, setSelectedMetric] = useState(metricsList[0].value);
  const authTypeMap = {
    BASIC: "BASIC",
    API_KEY: "API KEY",
    MC_AUTH: "MC AUTH",
  };

  const channelActionOptionsInternal = [
    { key: "none", value: "No Action" },
    { key: "suppress", value: "Suppress Support Events" },
    { key: "unsuppress", value: "Unsuppress Support Events" },
    { key: "db", value: "Delete Database Entries" },
    { key: "resource", value: "Delete Cloud Infrastructure and MC Channels" },
    {
      key: "all",
      value: "Delete Cloud Infrastructure, MC Channels and Database Entries",
    },
  ];

  const channelActionOptionsExternal = [
    { key: "none", value: "No Action" },
    { key: "suppress", value: "Suppress Support Events" },
    { key: "unsuppress", value: "Unsuppress Support Events" },
    { key: "db", value: "Delete Database Entries" },
  ];

  const [multiSelected, setMultiSelected] = useState([]);
  const [channelActionOptions, setChannelActionOptions] = useState(
    channelActionOptionsExternal
  );
  const [isCodebuildAllowed, setIsCodebuildAllowed] = useState(false);

  const [alert, setAlert] = useState(null);
  const [nameEdit, setNameEdit] = useState(false);
  const [clientEnvironmentNameValue, setClientEnvironmentNameValue] =
    useState("");

  const iconClass = {
    color: "#23ccef",
    position: "relative",
    left: "10px",
    fontSize: "20px",
    cursor: "pointer",
  };
  const iconClass1 = {
    color: "#fb414b",
    position: "relative",
    left: "25px",
    fontSize: "20px",
    cursor: "pointer",
  };
  const inputClass = {
    all: "unset",
    backgroundColor: "transparent",
    border: "1px solid #000000",
    borderRadius: "3px",
    padding: "3px",
    minWidth: `${clientEnvironmentNameValue?.length}ch`,
  };
  // Pagination Starts
  const columnMapping = {
    Status: "channel_status",
    "Support Tier": "support_tier",
    "Channel Name": "channel_name",
    "Support Event Suppressed": "suppress_support_event",
    Received: "received_messages",
    Queued: "queued_messages",
    Filtered: "filtered_messages",
    Errored: "errored_messages",
    "Sent/Transformed": "sent_messages",
    "Avg Process Time (sec)": "avg_process_time",
    "Auth Type": "mc_auth_type",
    "Last Reviewed": "last_reviewed_by_support",
  };

  const headers = [
    { key: "channel_status", label: "Channel Status" },
    { key: "support_tier", label: "Support Tier" },
    { key: "channel_name", label: "Channel Name" },
    { key: "suppress_support_event", label: "Support Event Suppressed" },
    { key: "received_messages", label: "Received Messages" },
    { key: "queued_messages", label: "Queued Messages" },
    { key: "filtered_messages", label: "Filtered Messages" },
    { key: "errored_messages", label: "Errored Messages" },
    { key: "sent_messages", label: "Sent Messages" },
    { key: "avg_process_time", label: "Avg Process Time" },
    { key: "mc_auth_type", label: "Mc Auth Type" },
    { key: "last_reviewed_by_support", label: "Last Reviewed" },
  ];
  const [totalCount, setTotalCount] = useState(0);
  const numberOfRowsData = [25, 50, 100];

  const [currentLimit, setCurrentLimit] = useState(numberOfRowsData[1]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [ascOrDescValue, setAscOrDescValue] = useState("ASC");
  const [sortValue, setSortValue] = useState("Channel Name");

  const setStateForPagnination = async (key, value) => {
    if (key === "currentLimit") {
      setCurrentLimit(value);
    } else if (key === "offset") {
      setCurrentOffset(value);
    } else if (key === "ascOrDesc") {
      setAscOrDescValue(value);
    } else {
      setSortValue(value);
    }
  };
  // Pagination Ends

  const fetchClientEnvironment = async (
    variables = {},
    isCSVDownload = false
  ) => {
    try {
      setShowLoader(true);
      const payload = {
        isCustomDate,
        sortFilter: variables.sortFilter
          ? variables.sortFilter
          : columnMapping[sortValue],
        client_id: clientId,
        client_environment_id: clientEnvironmentId,
        start_date: isCustomDate && startDate ? startDate : undefined,
        end_date: isCustomDate && endDate ? endDate : undefined,
        metric_date_list: !isCustomDate
          ? convertDateListToString(metricDateList)
          : undefined,
      };
      variables = { ...variables, ...payload };
      const response = await API.graphql(
        graphqlOperation(getClientEnvironment, variables)
      );
      if (response.data?.getClientEnvironment?.success) {
        if (!isCSVDownload) {
          const data = _.get(
            response,
            "data.getClientEnvironment.body.client_environment_data"
          );
          const totalChannelCount = _.get(
            response,
            "data.getClientEnvironment.body.total_channel_count"
          );
          setTotalCount(totalChannelCount);
          setClientEnvironmentNameValue(data?.client_environment_name);
          setClientEnvironmentData(data);
          let last_calculated_values = [];
          data.channels.forEach((channel) => {
            if (channel.last_calculated) {
              last_calculated_values.push(new Date(channel.last_calculated));
            }
          });
          const max_last_calculated =
            last_calculated_values.length > 0
              ? new Date(Math.max.apply(null, last_calculated_values))
              : null;
          setJobRanAt(max_last_calculated);
          setDeployStatus(data.deploy_status);
          setIsCodebuildAllowed(isStatusInAccessibleState(data.deploy_status));
        } else {
          const filter = {
            "Client Environment Id": payload.client_environment_id,
            "Metric Date List": payload.metric_date_list,
            "Start Date": payload.start_date,
            "End Date": payload.end_date,
          };
          const messageDataForCSV = _.get(
            response,
            "data.getClientEnvironment.body.client_environment_data.channels"
          );
          return { messageDataForCSV, filter };
        }
      } else {
        console.error(response.data?.getClientEnvironment?.message);
      }
      return response.data?.getClientEnvironment?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  const environmentResourceDetails = async () => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(getEnvironmentResourceDetails, {
          clientEnvironmentId: clientEnvironmentId,
        })
      );
      if (response.data?.getEnvironmentResourceDetails?.success) {
        const data = response.data.getEnvironmentResourceDetails.body;
        if (
          !data.db_secrets &&
          !data.mc_secrets &&
          !data.mc_authorized_data_sources &&
          !data.mc_nlb_dns_name
        ) {
          setResourceDetails(null);
          return;
        }
        setResourceDetails(data);
        setResourceDetailsModalOpen(true);
        notify("success");
      } else {
        console.error(response.data?.getEnvironmentResourceDetails?.message);
        notify("danger");
      }
    } catch (error) {
      console.error(error);
      notify("danger");
    } finally {
      setShowLoader(false);
    }
  };

  const changeClientEnvironmentName = async () => {
    try {
      setAlert(
        <SweetAlert
          showCancel
          title={"Are you sure?"}
          onConfirm={async () => {
            setShowLoader(true);
            const variables = {
              client_environment_id: clientEnvironmentId,
              client_environment_name: clientEnvironmentNameValue,
            };
            const response = await API.graphql(
              graphqlOperation(updateClientEnvironmentName, variables)
            );
            if (response.data?.updateClientEnvironmentName?.success) {
              notify(
                "success",
                response.data?.updateClientEnvironmentName?.body?.response
              );
              const filterObj = {
                isCustomDate,
                startDate,
                endDate,
                offSet: 0,
                sortFilter: columnMapping[sortValue],
                sortType: ascOrDescValue,
                limit: currentLimit,
              };
              await fetchClientEnvironment(filterObj);
              setNameEdit(false);
              setAlert(null);
            } else {
              console.error(
                response.data?.updateClientEnvironmentName?.message
              );
            }
            return response.data?.updateClientEnvironmentName?.success;
          }}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
        >
          You want to update the client environment's name to "
          {clientEnvironmentNameValue}" ?
        </SweetAlert>
      );
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  const createMCUser = async () => {
    try {
      notify("info", "MC User creation requested");
      const response = await API.graphql(
        graphqlOperation(addMCUser, {
          client_environment_id: clientEnvironmentId,
          user_id: props.user.attributes.sub,
          user_email: props.user.attributes.email,
        })
      );
      if (response.data?.addMCUser?.success) {
        const mc_user = JSON.parse(response.data.addMCUser.message);
        setMCUserData(mc_user);
        setMCUserModalOpen(true);
      } else {
        console.error(response.data?.addMCUser?.message);
        notify("danger", "Failed to create the MC User");
      }
    } catch (error) {
      console.error(error);
      notify("danger", "Failed to process MC User create request");
    }
  };

  const requestRefreshChannelTokens = async () => {
    try {
      notify("info", "Refresh channel tokens requested");
      const response = await API.graphql(
        graphqlOperation(refreshChannelTokens, {
          client_environment_id: clientEnvironmentId,
        })
      );
      if (response.data?.refreshChannelTokens?.success) {
        notify("success", "Refresh channel tokens requested successfully");
      } else {
        console.error(response.data?.refreshChannelTokens?.message);
        notify("danger", "Failed requesting channel token refresh");
      }
    } catch (error) {
      console.error(error);
      notify("danger", "Failed to process refresh channel token request");
    }
  };

  const [multipleExpandablePanels, setMultipleExpandablePanels] = useState([]);
  const toggleMultipleExpandablePanels = (event, value) => {
    event.preventDefault();
    if (multipleExpandablePanels.includes(value)) {
      setMultipleExpandablePanels(
        multipleExpandablePanels.filter((prop) => prop !== value)
      );
    } else {
      setMultipleExpandablePanels([...multipleExpandablePanels, value]);
    }
  };

  const handleChannelActionSubmit = async (
    client_id,
    client_environment_id,
    channel_list,
    user_id
  ) => {
    let payload = {};
    payload.client_environment_id = client_environment_id;
    payload.user_id = user_id;

    let suppress_list = [];
    let unsuppress_list = [];
    let delete_resource_list = [];
    let delete_db_list = [];

    for (
      let channelActionOptionIndex = 0;
      channelActionOptionIndex < multiSelected.length;
      channelActionOptionIndex++
    ) {
      if (multiSelected[channelActionOptionIndex].key == "suppress") {
        suppress_list.push(channel_list[channelActionOptionIndex].channel_id);
      } else if (multiSelected[channelActionOptionIndex].key == "unsuppress") {
        unsuppress_list.push(channel_list[channelActionOptionIndex].channel_id);
      } else if (multiSelected[channelActionOptionIndex].key == "resource") {
        delete_resource_list.push(
          channel_list[channelActionOptionIndex].channel_id
        );
      } else if (multiSelected[channelActionOptionIndex].key == "db") {
        delete_db_list.push(channel_list[channelActionOptionIndex].channel_id);
      } else if (multiSelected[channelActionOptionIndex].key == "all") {
        delete_resource_list.push(
          channel_list[channelActionOptionIndex].channel_id
        );
        delete_db_list.push(channel_list[channelActionOptionIndex].channel_id);
      }
    }

    payload.suppress_list = suppress_list;
    payload.unsuppress_list = unsuppress_list;
    payload.delete_resource_list = delete_resource_list;
    payload.delete_db_list = delete_db_list;

    try {
      if (
        suppress_list.length == 0 &&
        unsuppress_list.length == 0 &&
        delete_resource_list.length == 0 &&
        delete_db_list.length == 0
      ) {
        notify(
          "info",
          "Channel action request was not sent because no actions were changed."
        );
      } else {
        notify("info", "Channel action requested.");
        const response = await API.graphql(
          graphqlOperation(postChannelActionRequest, payload)
        );

        if (response.data?.postChannelActionRequest?.success) {
          notify("success", "Successfully sent channel action request.");
          history.push(`/admin/clients/${client_id}`);
        } else {
          notify("warning", "Failed to send channel action request.");
        }
      }
    } catch (e) {
      notify("warning", "Failed to get response after channel action request.");
    }
  };

  function handleMultiSelect(key, event, index) {
    let new_list = [];
    for (
      let select_index = 0;
      select_index < multiSelected.length;
      select_index++
    ) {
      if (select_index == index) {
        new_list.push({ key: key, value: event.target.text });
      } else {
        new_list.push(multiSelected[select_index]);
      }
    }
    setMultiSelected(new_list);
  }

  function resetSelect(num_select, defaultSelect) {
    let new_list = [];
    for (
      let num_select_index = 0;
      num_select_index < num_select;
      num_select_index++
    ) {
      new_list.push(channelActionOptions[defaultSelect]);
    }
    setMultiSelected(new_list);
  }

  function handleChannelActionAllSelect(key, num_select) {
    let selected_index = channelActionOptions.findIndex((x) => x.key === key);
    resetSelect(num_select, selected_index);
  }

  useEffect(() => {
    async function fetchData() {
      const filterObj = {
        isCustomDate,
        startDate,
        endDate,
        offSet: 0,
        sortFilter: columnMapping[sortValue],
        limit: currentLimit,
      };
      setCurrentOffset(0);
      const isSuccess = await fetchClientEnvironment(filterObj);
      if (!isSuccess) {
        notify("danger");
        history.goBack();
      }
    }
    fetchData();
  }, []);

  const mapSupportTier = (level) => {
    switch (level) {
      case 1:
        return "Low";
      case 2:
        return "Medium";
      case 3:
        return "Critical";
      default:
        return "N/A";
    }
  };

  const removeMetric = (index) =>
    setMetrics((oldMetrics) => {
      const oldMetricsCopy = [...oldMetrics];
      oldMetricsCopy.splice(index, 1);
      return oldMetricsCopy;
    });

  const metricCardsHTML = metrics.map(function (metric_type, index) {
    // const parameterString = generateCloudWatchMetricParam(paramString);
    return (
      <Col lg="6" sm="12">
        <CloudwatchMetric
          key={index}
          client_environment_id={clientEnvironmentId}
          metric_type={String(metric_type)}
          index={index}
          removeMetric={removeMetric}
        />
      </Col>
    );
  });

  const metricsListHTML = metricsList.map(function (metric, index) {
    return (
      <ListGroupItem
        className={`list-group-item-action ${
          selectedMetric === metric.value ? "active" : ""
        }`}
        style={{
          cursor: "pointer",
        }}
        onClick={() => setSelectedMetric(metric.value)}
        tag="a"
        key={index}
      >
        {metric.label}
      </ListGroupItem>
    );
  });

  if (showLoader) return <Spinner animation="grow" />;

  // if (errorMessage) return errorMessage;

  return (
    <>
      {alert}
      <Container fluid>
        <Row>
          <Col md="12">
            <h3 style={{ textAlign: "center" }}>Environment Details</h3>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h5>Client Name: {clientEnvironmentData.client_name}</h5>
            <h5>
              Client Environment Name:&nbsp;
              {!nameEdit ? (
                <>
                  <span>{clientEnvironmentData?.client_environment_name}</span>
                  <i
                    className="fas fa-edit"
                    style={iconClass}
                    onClick={() => setNameEdit(true)}
                  ></i>
                </>
              ) : (
                <>
                  <Form.Control
                    type="text"
                    autoFocus
                    required
                    style={inputClass}
                    value={clientEnvironmentNameValue}
                    minLength="1"
                    maxLength="80"
                    onChange={(e) =>
                      setClientEnvironmentNameValue(e.target.value)
                    }
                    name="client_environment_name"
                  />
                  <span>
                    <i
                      className="fas fa-check"
                      style={iconClass}
                      onClick={() => {
                        if (clientEnvironmentNameValue.trim() === "") {
                          notify(
                            "warning",
                            "Client Environment Name cannot be empty"
                          );
                        } else {
                          changeClientEnvironmentName();
                        }
                      }}
                    ></i>
                    <i
                      className="fas fa-times"
                      style={iconClass1}
                      onClick={() => {
                        setClientEnvironmentNameValue(
                          clientEnvironmentData?.client_environment_name
                        );
                        setNameEdit(false);
                      }}
                    ></i>
                  </span>
                </>
              )}
            </h5>
            <h5>Client Environment Id: {clientEnvironmentId}</h5>
            <h5>Deploy Status: {deployStatus}</h5>
            <h5>
              Environment Status:{" "}
              <CustomStatus
                value={clientEnvironmentData.client_environment_status}
              />
            </h5>
            <h5>Spoke Name: {clientEnvironmentData.spoke_name}</h5>
            <h5>
              Spoke Type:{" "}
              {clientEnvironmentData.spoke_type == 0 ? "Internal" : "External"}
            </h5>
          </Col>
        </Row>
        {groupname === "internal" && (
          <Button
            variant="info"
            className="btn-round"
            style={{ marginLeft: "10px" }}
            type="button"
            disabled={clientEnvironmentData.channels?.length == 0}
            onClick={() => {
              if (clientEnvironmentData.spoke_type == 0)
                setChannelActionOptions(channelActionOptionsInternal);
              resetSelect(clientEnvironmentData.channels?.length, 0);
              setChannelActionModalOpen(true);
            }}
          >
            <i className="fas fa-running"></i> Channel Action
          </Button>
        )}
        <Button
          variant="info"
          className="btn-round"
          style={{ marginLeft: "10px" }}
          type="button"
          disabled={false}
          onClick={() => {
            requestRefreshChannelTokens();
          }}
        >
          <i className="fas fa-undo"></i> Refresh Channel Tokens
        </Button>
        <Link
          id="mc-server-info"
          variant="info"
          className="btn btn-info btn-round"
          style={{ position: "relative", left: "10px" }}
          to={{
            pathname: `/admin/mc-server-info`,
            state: {
              environment_ids: [
                {
                  label: clientEnvironmentData?.client_environment_name,
                  value: clientEnvironmentData?.client_environment_id,
                },
              ],
              client_ids: [
                {
                  label: clientEnvironmentData?.client_name,
                  value: clientEnvironmentData?.client_id,
                },
              ],
            },
          }}
        >
          <i className="fas fa-list"></i> MC Server Info
        </Link>
        <hr></hr>
        {clientEnvironmentData.spoke_type === 0 && groupname === "internal" && (
          <>
            <Row>
              <Col md="12">
                <Button
                  variant="info"
                  className="btn-round"
                  style={{ marginLeft: "10px" }}
                  type="button"
                  onClick={() => {
                    environmentResourceDetails();
                  }}
                >
                  <i className="fas fa-eye"></i> Show Resource Details
                </Button>
                <Button
                  variant="info"
                  className="btn-round"
                  style={{ marginLeft: "10px" }}
                  type="button"
                  onClick={() => {
                    createMCUser();
                  }}
                >
                  <i className="fa fa-user"></i> My MC User
                </Button>
                {isCodebuildAllowed && (
                  <>
                    <Link
                      className="btn btn-round btn-info"
                      style={{ marginLeft: "10px" }}
                      to={{
                        pathname: `/admin/clients/${clientId}/environments/${clientEnvironmentId}/update-environment`,
                      }}
                    >
                      <i className="fas fa-edit"></i> Update Environment
                    </Link>
                    <Link
                      className="btn btn-round btn-info"
                      style={{ marginLeft: "10px" }}
                      to={{
                        pathname: `/admin/clients/${clientId}/environments/${clientEnvironmentId}/update-channels`,
                      }}
                    >
                      <i className="fas fa-edit"></i> Modify Channels
                    </Link>
                  </>
                )}
              </Col>
            </Row>
            <hr></hr>
            <Row>
              {metricCardsHTML}
              {groupname == "internal" && (
                <>
                  <Col md="2">
                    <Button
                      variant="info"
                      className="btn-round"
                      type="button"
                      onClick={() => setModalOpen(true)}
                    >
                      <i className="fas fa-plus"></i> Add Metric
                    </Button>
                  </Col>
                </>
              )}
            </Row>
            <hr></hr>
          </>
        )}
        <Row>
          {isCustomDate ? (
            <>
              <Col md="2">
                <CustomReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Start Time",
                  }}
                  timeFormat="h:mm A"
                  value={tempStartDate}
                  onChange={(e) => setTempStartDate(e)}
                ></CustomReactDatetime>
              </Col>
              <Col md="2">
                <CustomReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "End Time",
                  }}
                  timeFormat="h:mm A"
                  value={tempEndDate}
                  onChange={(e) => setTempEndDate(e)}
                ></CustomReactDatetime>
              </Col>
            </>
          ) : (
            <>
              <Col md="3">
                <label
                  style={{
                    paddingRight: "10px",
                  }}
                >
                  Select Date(s):
                </label>
                <CustomMultiDateSelect
                  value={metricDateList}
                  onChange={(e) => setMetricDateList(e)}
                ></CustomMultiDateSelect>
              </Col>
            </>
          )}
          <Col md="3">
            <label
              style={{
                position: "relative",
                top: "5px",
              }}
            >
              {!isCustomDate ? "Enable" : "Disable"} Custom Date Range
              <input
                type="checkbox"
                style={{
                  width: "30px",
                  height: "20px",
                  position: "relative",
                  top: "5px",
                }}
                defaultChecked={isCustomDate}
                onChange={(event) => {
                  setCustomDate(event.target.checked);
                }}
              />
            </label>
          </Col>
          <Col md="2">
            <Button
              variant="info"
              className="btn-round"
              type="button"
              onClick={async () => {
                setStartDate(tempStartDate);
                setEndDate(tempEndDate);

                const filterObj = {
                  isCustomDate,
                  startDate,
                  endDate,
                  offSet: 0,
                  sortFilter: columnMapping[sortValue],
                  sortType: ascOrDescValue,
                  limit: currentLimit,
                };
                setCurrentOffset(0);
                const isSuccess = await fetchClientEnvironment(filterObj);
                if (isSuccess) notify("success");
                else notify("danger");
              }}
            >
              {" "}
              Search
            </Button>
          </Col>
          <Col>
            {jobRanAt
              ? `Last calculated at ${jobRanAt.toLocaleString("en-US")}`
              : `No data to show for selected date(s)`}
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <Card className="strpied-tabled-with-hover">
                  <Card.Body className="table-responsive p-0">
                    <ReactTable
                      data={clientEnvironmentData.channels?.map(
                        (channel, index) => {
                          return {
                            id: channel.channel_id,
                            status: (
                              <CustomStatus value={channel.channel_status} />
                            ),
                            name: (
                              <Link
                                to={{
                                  pathname: `/admin/clients/${clientId}/environments/${clientEnvironmentId}/channels/${channel.channel_id}`,
                                }}
                                key={index}
                              >
                                {channel.channel_name}
                              </Link>
                            ),
                            action: (
                              <Link
                                to={{
                                  pathname: "/admin/find-or-reprocess",
                                  state: {
                                    ...channel,
                                    ...clientEnvironmentData,
                                    channelName: channel.channel_name,
                                    channelId: channel.channel_id,
                                    clientId: clientEnvironmentData.client_id,
                                    clientName:
                                      clientEnvironmentData.client_name,
                                    clientEnvironmentId: clientEnvironmentId,
                                    clientEnvironmentName:
                                      clientEnvironmentData.client_environment_name,
                                    isCustomDate: isCustomDate,
                                    startDate: startDate.format(),
                                    endDate: endDate.format(),
                                    isEndDateChanged: true,
                                  },
                                }}
                              >
                                Go to Messages
                              </Link>
                            ),
                            queue: channel.queued_messages,
                            received: channel.received_messages,
                            sent: channel.sent_messages,
                            filtered: channel.filtered_messages,
                            errors: channel.errored_messages,
                            support_tier: channel.support_tier,
                            suppress_support_event:
                              channel.suppress_support_event,
                            avg_process_time: channel.avg_process_time,
                            mc_auth_type: authTypeMap[channel.mc_auth_type],
                            lastReviewed: channel.last_reviewed_by_support
                              ? new Date(
                                  channel.last_reviewed_by_support
                                ).toLocaleString()
                              : "Not Reviewed",
                          };
                        }
                      )}
                      totalCount={totalCount}
                      functionCallBack={fetchClientEnvironment}
                      setStateForPagnination={setStateForPagnination}
                      currentLimit={currentLimit}
                      currentOffset={currentOffset}
                      ascOrDescValue={ascOrDescValue}
                      sortValue={sortValue}
                      numberOfRowsData={numberOfRowsData}
                      columnMapping={columnMapping}
                      tableName="Channels"
                      CSVHeaders={headers}
                      CSVFileName={
                        "Channels_" +
                        clientEnvironmentData.client_environment_name +
                        "_Export"
                      }
                      columns={[
                        {
                          Header: "Status",
                          accessor: "status",
                          filterable: false,
                          maxWidth: 80,
                        },
                        {
                          Header: "Support Tier",
                          accessor: (row) => {
                            return mapSupportTier(row.support_tier);
                          },
                          filterable: false,
                        },
                        {
                          Header: "Channel Name",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            return (
                              <CustomTooltip
                                id={`channel-id-${row.id}`}
                                value={row.name}
                                placement="right"
                              />
                            );
                          },
                          accessor: "name",
                          filterable: false,
                          minWidth: 200,
                        },
                        {
                          Header: "View Messages",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            return (
                              <CustomTooltip
                                id={`view-messages-${row.id}`}
                                value={row.action}
                                placement="left"
                              />
                            );
                          },
                          accessor: "action",
                          sortable: false,
                          filterable: false,
                          minWidth: 200,
                        },
                        {
                          Header: "Support Event Suppressed",
                          accessor: (row) => {
                            return row.suppress_support_event ? "Yes" : "No";
                          },
                          filterable: false,
                          minWidth: 220,
                        },
                        {
                          Header: "Received",
                          accessor: (row) => {
                            return row.received ? row.received : 0;
                          },
                          filterable: false,
                          maxWidth: 100,
                        },
                        {
                          Header: "Queued",
                          accessor: (row) => {
                            return row.queue ? row.queue : 0;
                          },
                          filterable: false,
                          maxWidth: 100,
                        },
                        {
                          Header: "Filtered",
                          accessor: (row) => {
                            return row.filtered ? row.filtered : 0;
                          },
                          filterable: false,
                          maxWidth: 100,
                        },
                        {
                          Header: "Errored",
                          accessor: (row) => {
                            return row.errors ? row.errors : 0;
                          },
                          filterable: false,
                          maxWidth: 100,
                        },
                        {
                          Header: "Sent/Transformed",
                          accessor: (row) => {
                            return row.sent ? row.sent : 0;
                          },
                          filterable: false,
                          minWidth: 160,
                        },
                        {
                          Header: "Avg Process Time (sec)",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            return (
                              <CustomTooltip
                                id={`avg-process-time-${row.channel_id}`}
                                value={
                                  Math.round(10000 * row.avg_process_time) /
                                  10000
                                }
                                placement="left"
                              />
                            );
                          },
                          accessor: "avg_process_time",
                          filterable: false,
                          minWidth: 200,
                        },
                        {
                          Header: "Auth Type",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            return (
                              <CustomTooltip
                                id={`mc-auth-type-${row.channel_id}`}
                                value={row.mc_auth_type}
                                placement="left"
                              />
                            );
                          },
                          accessor: "mc_auth_type",
                          filterable: false,
                          minWidth: 160,
                        },
                        {
                          Header: "Last Reviewed",
                          Cell: (cell) => {
                            const row = cell.row.original;
                            return (
                              <CustomTooltip
                                id={`last-reviewed-${row.channel_id}`}
                                value={row.lastReviewed}
                                placement="left"
                              />
                            );
                          },
                          accessor: "lastReviewed",
                          filterable: false,
                          minWidth: 200,
                        },
                      ]}
                      hidePagination={false}
                    />
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal
          toggle={() => setResourceDetailsModalOpen(!resourceDetailsModalOpen)}
          className="modal-large"
          isOpen={resourceDetailsModalOpen}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="resourceDetailsModalLabel">
              Resource Details
            </h5>
            <button
              id="resource-details-close"
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setResourceDetailsModalOpen(false)}
            >
              <span aria-hidden={true}>x</span>
            </button>
          </div>
          <ModalBody>
            {!resourceDetails ? (
              "Not able to fetch any resource details."
            ) : (
              <div
                className="accordions"
                id="environmentResourceDetailsAccordion"
              >
                {resourceDetails.db_secrets?.length > 0 && (
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">
                        <a
                          data-toggle="collapse"
                          aria-expanded={multipleExpandablePanels.includes(1)}
                          href="#pablo"
                          onClick={(e) => toggleMultipleExpandablePanels(e, 1)}
                        >
                          Database Secrets <b className="caret"></b>
                        </a>
                      </Card.Title>
                    </Card.Header>
                    <Collapse
                      className="card-collapse"
                      id="dbSecretCollapse"
                      in={multipleExpandablePanels.includes(1)}
                    >
                      <Card.Body>
                        <Table className="table-hover table-striped w-full">
                          <tbody>
                            {resourceDetails.db_secrets?.map((db_secret) => {
                              return (
                                <tr key={`db-secret-${db_secret.key}`}>
                                  <td>{db_secret.key}</td>
                                  <td>{db_secret.value}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Collapse>
                  </Card>
                )}
                {resourceDetails.mc_secrets?.length > 0 && (
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">
                        <a
                          data-toggle="collapse"
                          aria-expanded={multipleExpandablePanels.includes(2)}
                          href="#pablo"
                          onClick={(e) => toggleMultipleExpandablePanels(e, 2)}
                        >
                          Mirth Connect Secrets <b className="caret"></b>
                        </a>
                      </Card.Title>
                    </Card.Header>
                    <Collapse
                      className="card-collapse"
                      id="mcSecretCollapse"
                      in={multipleExpandablePanels.includes(2)}
                    >
                      <Card.Body>
                        <Table className="table-hover table-striped w-full">
                          <tbody>
                            {resourceDetails.mc_secrets?.map((mc_secret) => {
                              return (
                                <tr key={`mc-secret-${mc_secret.key}`}>
                                  <td>{mc_secret.key}</td>
                                  <td>{mc_secret.value}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Collapse>
                  </Card>
                )}
                {resourceDetails.mc_nlb_dns_name && (
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">
                        <a
                          data-toggle="collapse"
                          id="mc-address"
                          aria-expanded={multipleExpandablePanels.includes(3)}
                          href="#pablo"
                          onClick={(e) => toggleMultipleExpandablePanels(e, 3)}
                        >
                          MC Address <b className="caret"></b>
                        </a>
                      </Card.Title>
                    </Card.Header>
                    <Collapse
                      className="card-collapse"
                      id="mcNLBDNSCollapse"
                      in={multipleExpandablePanels.includes(3)}
                    >
                      <Card.Body>
                        <Table className="table-hover table-striped w-full">
                          <tbody>
                            <tr key={`nlb-dns`}>
                              <td>MC Admin DNS</td>
                              <td id="mc-admin-dns">{`https://${resourceDetails.mc_nlb_dns_name}:8443/`}</td>
                              <td>
                                <Button
                                  variant="info"
                                  className="btn-outline btn-round"
                                  type="button"
                                  onClick={() => {
                                    copyToClipboard(
                                      `https://${resourceDetails.mc_nlb_dns_name}:8443/`
                                    );
                                  }}
                                >
                                  <i className="fas fa-copy"></i>
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Collapse>
                  </Card>
                )}
                {resourceDetails.mc_authorized_data_sources?.length > 0 && (
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">
                        <a
                          data-toggle="collapse"
                          aria-expanded={multipleExpandablePanels.includes(4)}
                          href="#pablo"
                          onClick={(e) => toggleMultipleExpandablePanels(e, 4)}
                        >
                          Authorized Data Sources <b className="caret"></b>
                        </a>
                      </Card.Title>
                    </Card.Header>
                    <Collapse
                      className="card-collapse"
                      id="mcAuthorizedDataSourcesCollapse"
                      in={multipleExpandablePanels.includes(4)}
                    >
                      <Card.Body>
                        <Table className="table-hover table-striped w-full">
                          <tbody>
                            {resourceDetails.mc_authorized_data_sources?.map(
                              (mc_authorized_data_source, index) => {
                                return (
                                  <tr>
                                    <td>
                                      <Row>
                                        <Col md="12">
                                          API key value {index + 1}
                                        </Col>
                                      </Row>
                                    </td>
                                    <td>
                                      <Row>
                                        <Col md="4">User</Col>
                                        <Col md="8">
                                          {mc_authorized_data_source.user_name}
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md="4">API Key</Col>
                                        <Col md="8">
                                          <span>
                                            {mc_authorized_data_source.api_key}
                                          </span>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col md="4">Routes</Col>
                                        <Col md="8">
                                          {mc_authorized_data_source.routes.join(
                                            ", "
                                          )}
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Collapse>
                  </Card>
                )}
              </div>
            )}
          </ModalBody>
        </Modal>
        <Modal
          toggle={() => setMCUserModalOpen(!mcUserModalOpen)}
          isOpen={mcUserModalOpen}
          scrollable={true}
        >
          <style type="text/css">
            {`
              .btn-small {
                padding: 0.1rem 0.1rem;
                font-size: 10px;
              }
            `}
          </style>
          <div className="modal-header">
            <h5 className="modal-title" id="mcUserModalLabel">
              Mirth Connect Administrator User Info
            </h5>
          </div>
          <ModalBody>
            <div>
              <b>Username:</b> {mcUserData.username}
              <Button
                variant="info"
                className="btn-outline btn-round"
                type="button"
                size="small"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  copyToClipboard(mcUserData.username);
                }}
              >
                <i className="fas fa-copy"></i>
              </Button>
            </div>
            <div>
              <b>Password:</b> {mcUserData.mc_password}
              <Button
                variant="info"
                className="btn-outline btn-round"
                type="button"
                size="small"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  copyToClipboard(mcUserData.mc_password);
                }}
              >
                <i className="fas fa-copy"></i>
              </Button>
            </div>
            <br></br>
            <div>
              <b>
                This MC User is valid until{" "}
                {new Date(mcUserData.ttl).toLocaleString("en-US")}
              </b>
            </div>
            <br></br>
            <div>
              <b>MC Address:</b>
            </div>
            <Table className="table-hover table-striped w-full">
              <tbody>
                <tr key={`nlb-dns`}>
                  <td>{`https://${mcUserData.mc_nlb_dns_name}:8443/`}</td>
                  <td>
                    <Button
                      variant="info"
                      className="btn-outline btn-round"
                      type="button"
                      size="small"
                      style={{ marginLeft: "10px" }}
                      onClick={() => {
                        copyToClipboard(
                          `https://${mcUserData.mc_nlb_dns_name}:8443/`
                        );
                      }}
                    >
                      <i className="fas fa-copy"></i>
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div>
              <b>Copy All:</b>
              <Button
                variant="info"
                id="copy-all"
                className="btn-outline btn-round"
                type="button"
                size="small"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  copyToClipboard(
                    `Username:  ${mcUserData.username}\nPassword:  ${mcUserData.mc_password}\nNLB URL:   https://${mcUserData.mc_nlb_dns_name}:8443/`
                  );
                }}
              >
                <i className="fas fa-copy"></i>
              </Button>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              id="mc-user-modal-okay-button"
              variant="secondary"
              type="button"
              onClick={() => setMCUserModalOpen(false)}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          toggle={() => setChannelActionModalOpen(!channelActionModalOpen)}
          size="lg"
          isOpen={channelActionModalOpen}
          scrollable={false}
        >
          <div className="modal-header">
            <div className="modal-title" id="channelActionOptionsList">
              <h5>Select Channel Action Options</h5>
              {!isCodebuildAllowed && (
                <h5 className="text-danger">
                  *Note: No actions can be performed as this environment is
                  currently deploying.
                </h5>
              )}
            </div>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setChannelActionModalOpen(false)}
            >
              <span aria-hidden={true}>x</span>
            </button>
          </div>
          <ModalHeader>
            <DropdownButton
              id="dropdown-basic-button-all"
              variant="info"
              className="floatRight"
              disabled={!isCodebuildAllowed}
              onSelect={(key) =>
                handleChannelActionAllSelect(
                  key,
                  clientEnvironmentData.channels.length
                )
              }
              title="Apply to All "
            >
              {channelActionOptions.map((item, index) => {
                return (
                  <Dropdown.Item key={index} eventKey={item.key}>
                    {item.value}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </ModalHeader>
          <ModalBody>
            <Table>
              <thead>
                <tr>
                  <th>Channels</th>
                  <th>Select Action to Take</th>
                </tr>
              </thead>
              <tbody>
                {clientEnvironmentData.channels?.map((channel, index) => {
                  return (
                    <tr key={index}>
                      <td>{channel.channel_name}</td>
                      <td>
                        <DropdownButton
                          id={`dropdown-basic-button-${index}`}
                          variant="info"
                          className="floatRight"
                          disabled={!isCodebuildAllowed}
                          onSelect={(key, event) =>
                            handleMultiSelect(key, event, index)
                          }
                          title={
                            multiSelected[index]?.value ||
                            channelActionOptions[0].value
                          }
                        >
                          {channelActionOptions.map((item, index) => {
                            return (
                              <Dropdown.Item key={index} eventKey={item.key}>
                                {item.value}
                              </Dropdown.Item>
                            );
                          })}
                        </DropdownButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              type="button"
              onClick={() => setChannelActionModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="button"
              disabled={!isCodebuildAllowed}
              onClick={() => {
                handleChannelActionSubmit(
                  clientId,
                  clientEnvironmentId,
                  clientEnvironmentData.channels,
                  props.user.attributes.sub
                );
                setChannelActionModalOpen(false);
              }}
            >
              Submit
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          toggle={() => setModalOpen(!modalOpen)}
          isOpen={modalOpen}
          scrollable={true}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="metricListModalLabel">
              Select CloudWatch Metric
            </h5>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModalOpen(false)}
            >
              <span aria-hidden={true}>x</span>
            </button>
          </div>
          <ModalBody>
            <ListGroup>{metricsListHTML}</ListGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              type="button"
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                setMetrics((oldMetrics) => [...oldMetrics, selectedMetric]);
                setModalOpen(false);
              }}
            >
              Show Metric
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
}

export default withAuthenticator(Environment, { hideSignUp: true });
