import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

// react-bootstrap components
import {
  Button,
  Card,
  Row,
  Col,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import ReactTable from "components/ReactTable/ReactTableWithDynamicPagination.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { NotificationAlertContext } from "contexts/notificationAlertContext";
import {
  getSupportEventDetails,
  addSupportEventDetail,
  addSupportEvents,
} from "graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";

function SupportEvent({ user }) {
  // Hooks
  const notify = useContext(NotificationAlertContext);
  const history = useHistory();
  const location = useLocation();

  const { supportEventIdempotencyKey, eventType } =
    useParams();
  // Get a specific query parameter
  const clientEnvironmentId = new URLSearchParams(location.search).get("clientEnvironmentId");

  const emailId = user?.attributes?.email;
  const state = location.state;

  const [alert, setAlert] = useState(null);
  const [supportEventDetails, setSupportEventDetails] = useState({});
  const [tableData, setTableData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const sweetAlertStyle = { display: "block", marginTop: "-100px" };

  const ellipsisStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
  const headers = [
    { key: "event_comments", label: "Event Comments" },
    { key: "eval_date", label: "Eval Date" },
    { key: "user_id", label: "User Id" },
  ];

  // Pagination Starts
  const columnMapping = {
    "Comment Date": "eval_date",
    "Commented By": "user_id",
    Comments: "event_comments",
  };
  const [totalCount, setTotalCount] = useState(0);
  const numberOfRowsData = [25, 50, 100];

  const [currentLimit, setCurrentLimit] = useState(numberOfRowsData[1]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [ascOrDescValue, setAscOrDescValue] = useState("ASC");
  const [sortValue, setSortValue] = useState("Comment Date");

  const setStateForPagnination = async (key, value) => {
    if (key === "currentLimit") {
      setCurrentLimit(value);
    } else if (key === "offset") {
      setCurrentOffset(value);
    } else if (key === "ascOrDesc") {
      setAscOrDescValue(value);
    } else {
      setSortValue(value);
    }
  };
  // Pagination Ends

  const fetchSupportEventDetails = async (
    variables = {},
    isCSVDownload = false
  ) => {
    try {
      setShowLoader(true);
      const payload = {
        sortFilter: variables.sortFilter
          ? variables.sortFilter
          : columnMapping[sortValue],
        client_environment_id: clientEnvironmentId,
        support_event_idempotency_key: supportEventIdempotencyKey,
        event_type: eventType,
      };
      variables = { ...variables, ...payload };
      const response = await API.graphql(
        graphqlOperation(getSupportEventDetails, variables)
      );
      if (response.data?.getSupportEventDetails?.success) {
        if (!isCSVDownload) {
          setSupportEventDetails(
            response.data.getSupportEventDetails?.body?.support_event_data
              ?.support_event
          );
          setTotalCount(
            response.data.getSupportEventDetails?.body?.total_count
          );
          setTableData(
            response.data.getSupportEventDetails?.body?.support_event_data
              ?.support_event_details
          );
        } else {
          const filter = {
            "Client Environment Id": clientEnvironmentId,
            "Support Event Idempotency Key": supportEventIdempotencyKey,
          };
          const messageDataForCSV = _.get(
            response,
            "data.getSupportEventDetails.body.support_event_data.support_event_details"
          );
          return { messageDataForCSV, filter };
        }
      } else {
        console.error(response.data?.getSupportEventDetails?.message);
      }
      return response.data?.getSupportEventDetails?.success;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setShowLoader(false);
    }
  };

  const addSupportEventDetailData = async (parameters) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(addSupportEventDetail, parameters)
      );
      if (response.data?.addSupportEventDetail?.success) {
        notify("success", "Successfully added new comment.");
      } else {
        console.error(response.data?.addSupportEventDetail?.message);
        notify("danger", "Could not add new comment.");
      }
    } catch (error) {
      console.error(error);
      notify("danger", "Could not add new comment.");
    } finally {
      setShowLoader(false);
    }
  };

  const addSupportEvent = async (parameters) => {
    try {
      setShowLoader(true);
      const response = await API.graphql(
        graphqlOperation(addSupportEvents, parameters)
      );
      if (response.data?.addSupportEvents?.success) {
        notify("success", "Successfully updated this support event state.");
      } else {
        console.error(response.data?.addSupportEvents?.message);
        notify("danger", "Could not update this support event state.");
      }
    } catch (error) {
      console.error(error);
      notify("danger", "Could not update this support event state.");
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const filterObj = {
        offSet: 0,
        limit: currentLimit,
        sortFilter: "eval_date",
        sortType: "ASC",
      };
      setCurrentOffset(0);
      const isSuccess = await fetchSupportEventDetails(filterObj);
      if (!isSuccess) {
        notify("danger");
        history.goBack();
      }
    }
    fetchData();
  }, []);

  if (showLoader) return <Spinner animation="grow" />;

  // if (errorMessage) return errorMessage;

  const inputAlert = (confirmBtnText, confirmText) => {
    setAlert(
      <SweetAlert
        input
        inputType="textarea"
        showCancel
        style={sweetAlertStyle}
        title="Please add some comments..."
        onConfirm={(e) => successAlert(e, confirmText)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText={confirmBtnText}
        cancelBtnText="Cancel"
      />
    );
  };
  const successAlert = (e, confirmText) => {
    const now = new Date();
    const comment = {
      eval_date: now.toISOString(),
      event_comments: e,
      user_id: emailId,
    };
    setTableData((oldTableData) => {
      return [...oldTableData, comment];
    });
    let event_end = null;
    let event_resolved_by = null;
    if (confirmText === "Resolved!") {
      event_end = now.toISOString();
      event_resolved_by = emailId;
      setSupportEventDetails((oldDetails) => ({
        ...oldDetails,
        event_end: now.toISOString(),
        event_resolved_by: emailId,
      }));
    } else if (confirmText === "Unresolved!") {
      setSupportEventDetails((oldDetails) => ({
        ...oldDetails,
        event_end: null,
        event_resolved_by: null,
      }));
    }
    if (confirmText === "Resolved!" || confirmText === "Unresolved!") {
      addSupportEvent({
        support_event_idempotency_key:
          supportEventDetails.support_event_idempotency_key,
        support_event_correlation_id:
          supportEventDetails.support_event_correlation_id,
        client_environment_id: supportEventDetails.client_environment_id,
        channel_id: supportEventDetails.channel_id,
        event_description: e,
        event_start: supportEventDetails.event_start,
        event_end: event_end,
        event_raised_by: supportEventDetails.event_raised_by,
        event_resolved_by: event_resolved_by,
        aws_namespace: supportEventDetails.aws_namespace,
        aws_name: supportEventDetails.aws_name,
        event_type: supportEventDetails.event_type,
        event_label: supportEventDetails.event_label,
      });
    } else if (confirmText === "Updated!") {
      addSupportEventDetailData({
        client_environment_id: clientEnvironmentId,
        support_event_idempotency_key:
          supportEventDetails.support_event_idempotency_key,
        eval_date: now.toISOString(),
        event_comments: e,
        email_id: emailId,
      });
    }
    setAlert(
      <SweetAlert
        success
        style={sweetAlertStyle}
        title={confirmText}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
      />
    );
  };

  const transformDates = (str) => {
    const regex =
      /(?:\d{4}-\d{2}-\d{2})(?:[ T]\d{2}:\d{2}:\d{2})(?:[.,]\d{1,6})?(?:[+]\d{2}:?(?:\d{2})?)?/gm;
    const dates = str.match(regex) || [];
    for (const date of dates) {
      str = str.replaceAll(date, new Date(date).toLocaleString());
    }
    return str;
  };

  return (
    <>
      {alert}
      <Row>
        <Col md="2">
          <Link
            className="btn btn-info"
            to={{
              pathname: state?.client_id
                ? `/admin/clients/${state?.client_id}`
                : `/admin/support-events`,
              state: {
                ...state,
              },
            }}
          >
            Go Back
          </Link>
        </Col>
        <Col md="8">
          <h3 style={{ textAlign: "center" }}>Support Event Detail</h3>
        </Col>
        <Col md="2"></Col>
      </Row>
      <Row>
        <Col md="12">
          <h5>Event Type: {supportEventDetails.event_type}</h5>
          {supportEventDetails.client_name && (
            <>
              <h5>Client Name: {supportEventDetails.client_name}</h5>
            </>
          )}
          {supportEventDetails.client_environment_name && (
            <>
              <h5>
                Client Environment Name:{" "}
                {supportEventDetails.client_environment_name}
              </h5>
            </>
          )}
          {supportEventDetails.channel_name && (
            <>
              <h5>Channel Name: {supportEventDetails.channel_name}</h5>
            </>
          )}
          <h5>
            Event Started:{" "}
            {new Date(supportEventDetails.event_start).toLocaleString()}
          </h5>
          {supportEventDetails.event_end && (
            <>
              <h5>
                Event Ended:{" "}
                {new Date(supportEventDetails.event_end).toLocaleString()}
              </h5>
            </>
          )}
          {supportEventDetails.event_label && (
            <>
              <h5>Label: {transformDates(supportEventDetails.event_label)}</h5>
            </>
          )}
        </Col>
      </Row>
      <hr></hr>
      <Row>
        <Col md="12">
          <Card>
            <Card.Body>
              <Card className="strpied-tabled-with-hover">
                <Card.Body className="table-responsive p-0">
                  <ReactTable
                    data={tableData}
                    totalCount={totalCount}
                    functionCallBack={fetchSupportEventDetails}
                    setStateForPagnination={setStateForPagnination}
                    currentLimit={currentLimit}
                    currentOffset={currentOffset}
                    ascOrDescValue={ascOrDescValue}
                    sortValue={sortValue}
                    numberOfRowsData={numberOfRowsData}
                    columnMapping={columnMapping}
                    tableName="Support Event Details"
                    CSVHeaders={headers}
                    CSVFileName="Support_Event_Details_Export"
                    notify={notify}
                    columns={[
                      {
                        Header: "Comment Date",
                        accessor: "eval_date",
                        filterable: false,
                        maxWidth: 200,
                        Cell: (cell) => {
                          const row = cell.row.original;
                          const date = new Date(row.eval_date).toLocaleString();
                          return <div style={ellipsisStyle}>{date}</div>;
                        },
                      },
                      {
                        Header: "Commented By",
                        accessor: "user_id",
                        maxWidth: 300,
                        filterable: false,
                        showTooltip: true,
                      },
                      {
                        Header: "Comments",
                        accessor: "event_comments",
                        minWidth: 600,
                        filterable: false,
                        showTooltip: true,
                      },
                    ]}
                  />
                </Card.Body>
              </Card>
            </Card.Body>
            <Card.Footer style={{ textAlign: "right" }}>
              {!supportEventDetails.event_end && (
                <>
                  <Button
                    variant="info"
                    type="button"
                    onClick={() => inputAlert("Update event!", "Updated!")}
                    style={{ marginRight: "10px" }}
                  >
                    Update Event
                  </Button>
                  <Button
                    variant="info"
                    type="button"
                    onClick={() => inputAlert("Resolve event!", "Resolved!")}
                  >
                    Resolve Event
                  </Button>
                </>
              )}
              {supportEventDetails.event_end && (
                <>
                  <Button
                    variant="warning"
                    type="button"
                    onClick={() =>
                      inputAlert("Unresolve event!", "Unresolved!")
                    }
                  >
                    Unresolve Event
                  </Button>
                </>
              )}
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default withAuthenticator(SupportEvent, { hideSignUp: true });
